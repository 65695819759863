<template>
  <div class="LogIn" v-fixed>
    <!-- 表单 -->
    <div class="form" @keydown.enter="validate">
      <div class="topbar">
        <img @click="$router.push('/')" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
      </div>

      <div class="form-title">Log In!</div>
      <v-card class="my-5" elevation="0">
        <v-form class="my-5" ref="form" style="  background-color: #F0F6F9;">
          <v-text-field
            dense
            :placeholder="$t('請輸入用戶名')"
            color="info"
            outlined
            clearable
            v-model="moblie"
            :rules="[rules.required]"
            prepend-inner-icon="mdi-account"
            class="rounded-lg"
          ></v-text-field>
          <v-text-field
            dense
            color="info"
            prepend-inner-icon="mdi-lock"
            :placeholder="$t('請輸入密碼')"
            outlined
            clearable
            :type="'password'"
            v-model="password"
            :rules="[rules.required]"
            class="rounded-lg"
          ></v-text-field>
          <div
            class="px-1 d-flex justify-end find-password"
            @click="$router.push('/forgetpassword')"
          >
            {{ $t("找回密碼") }}
          </div>
          <v-btn
            height="50"
            block
            class="rounded-lg mt-5 btn1"
            bottom
            @click="validate"
          >
            {{ $t("登錄帳號") }}
          </v-btn>
          <v-btn
            height="50"
            block
            class="rounded-lg mt-5 btn2"
            elevation="0"
            bottom
            @click="$router.push('/signup')"
          >
            {{ $t("註冊") }}
          </v-btn>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/index";
export default {
  data() {
    return {
      alert: true,
      moblie: "",
      password: "",
      token: null,
      rules: {
        required: (value) => !!value || i18n.t("必填項"),
      },
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    if (this.token) {
      this.$router.push("/UserInfo");
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$post("/api/Account/Login", {
          account: this.moblie,
          password: this.password,
          gameId: process.env.VUE_APP_API_BASE_GameId,
        }).then((res) => {
          this.$toast(i18n.t("登陸成功"));
          localStorage.setItem("token", res.data.token);
          this.$router.push("/");
        });
      }
    },
  },
  directives: {
    fixed: {
      // inserted 被绑定元素插入父节点时调用
      inserted() {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        document.body.style.cssText +=
          "position:fixed;width:100%;top:-" + scrollTop + "px;";
      },
      // unbind 指令与元素解绑时调用
      unbind() {
        let body = document.body;
        body.style.position = "";
        let top = body.style.top;
        document.body.scrollTop = document.documentElement.scrollTop =
          -parseInt(top);
        body.style.top = "";
      },
    },
  },
};
</script>
<style lang="scss" >
#app{
  background-color: #F0F6F9;
}
</style>

<style lang="scss" scoped>
.LogIn {
  // 登录表单
  background-color: #F0F6F9;

  .form {
    padding: 46px 24px 0;

    .topbar {
      display: flex;
    align-items: center;
      background-color: #F0F6F9;
      text-align: left;
      height: 46px;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 100;
      .v-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 24px;
      }
      .head-title {
        position: absolute;
        line-height: 1;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        left: 50%;
        font-size: 18px;
        color: #000;
      }
    }
    .form-title {
      margin-top: 20px;
      padding: 0 3px;
      text-align: left;
      font-weight: 400;
font-size: 28px;
line-height: 39px;
color: #485573;
    }
    .find-password {
      line-height: 15px;
      font-size: 14px;
      color: #485573;
    }
    .btn1 {
      background: linear-gradient(91.69deg, #f84077 16.39%, #f95e93 139.39%);
      box-shadow: 0px 15px 20px rgba(245, 86, 136, 0.2);
      color: #fff;
      font-size: 16px;
    }
    .btn2 {
      color: #f95386;
      background: #ffffff;
      border: 1px solid #f8487e;
      font-size: 16px;
    }
  }
}
</style>
